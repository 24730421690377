import './App.css';
import PageRoutes from './routes';

function App() {
  return (
    <PageRoutes />
  );
}

export default App;
